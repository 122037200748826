body {
  margin: 0;
  font-family: 'Lato', 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  background-color: #f9f8f7; 
}

.searchBox {
  background-color: white;
  height: 2rem;
  border-radius: 4px;
}

.inputBox {
  background-color: white;
  width:100%;
  padding-left: -5%;
  margin-right: -5%;
  border-radius: 4px;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  margin-bottom: 0; 
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: rgba(50, 151, 211, 0.25);
    box-shadow: none; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); 
  }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 1.25rem);
    padding: 0.625rem 0.75rem;
    line-height: 1.5;
    color: #8898aa;
    content: "Browse";
    background-color: #fff;
    border-left: inherit;
    border-radius: 0 0.375rem 0.375rem 0; 
  }

  .snackBar {
    position: relative !important;
    top: 0 !important;
  }

  .resource-column-header {
    font-weight: bold;
    background-color: black;
    color: white;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
  }

  .MuiDataGrid-iconButtonContainer button {
    color: white;
  }